import request from '@/utils/request';

// 仓库模板
export function warehouseTemplate(params) {
  return request({
    url: '/warehouses/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 仓库导入
export function warehouseImport(data) {
  return request({
    url: '/warehouses/import_data/',
    method: 'post',
    data,
  })
}

// 库区模板
export function reservoirAreaTemplate(params) {
  return request({
    url: '/reservoir_areas/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 库区导入
export function reservoirAreaImport(data) {
  return request({
    url: '/reservoir_areas/import_data/',
    method: 'post',
    data,
  })
}

// 库位模板
export function locationsTemplate(params) {
  return request({
    url: '/locations/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 库位导入
export function locationsImport(data) {
  return request({
    url: '/locations/import_data/',
    method: 'post',
    data,
  })
}

// 客户模板
export function clientTemplate(params) {
  return request({
    url: '/clients/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 客户导入
export function clientImport(data) {
  return request({
    url: '/clients/import_data/',
    method: 'post',
    data,
  })
}

export function clientMaterialImport(data, clientId) {
  return request({
    url: `/clients/${clientId}/import_material_data/`,
    method: 'post',
    data,
  })
}

// 单位模板
export function unitsTemplate(params) {
  return request({
    url: '/units/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 单位导入
export function unitsImport(data) {
  return request({
    url: '/units/import_data/',
    method: 'post',
    data,
  })
}

// 产品分类模板
export function categoriesTemplate(params) {
  return request({
    url: '/categories/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 产品分类导入
export function categoriesImport(data) {
  return request({
    url: '/categories/import_data/',
    method: 'post',
    data,
  })
}

// 产品模板
export function materialsTemplate(params) {
  return request({
    url: '/materials/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 产品导入
export function materialsImport(data) {
  return request({
    url: '/materials/import_data/',
    method: 'post',
    data,
  })
}

export function materialsImportUpdate(data) {
  return request({
    url: '/materials/import_update/',
    method: 'post',
    data,
  })
}

// 订单类型模板
export function orderTypesTemplate(params) {
  return request({
    url: '/order_types/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

// 订单类型导入
export function orderTypesImport(data) {
  return request({
    url: '/order_types/import_data/',
    method: 'post',
    data,
  })
}

export function receiptOrderTemplate(params) {
  return request({
    url: '/receipt_orders/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function receiptOrderImport(data) {
  return request({
    url: '/receipt_orders/import_data/',
    method: 'post',
    data,
  })
}


export function deliveryOrderTemplate(params) {
  return request({
    url: '/delivery_orders/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function deliveryOrderImport(data) {
  return request({
    url: '/delivery_orders/import_data/',
    method: 'post',
    data,
  })
}


export function deliveryOrderSupplementTemplate(params) {
  return request({
    url: '/delivery_orders/supplement_import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function deliveryOrderSupplementImport(data) {
  return request({
    url: '/delivery_orders/supplement_import_data/',
    method: 'post',
    data,
  })
}

export function subBatchTemplate(params) {
  return request({
    url: '/sub_batchs/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function subBatchOrderImport(data) {
  return request({
    url: '/sub_batchs/import_data/',
    method: 'post',
    data,
  })
}

