export default [
  {
    title: '序号',
    dataIndex: 'index',
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: '产品编号',
    dataIndex: 'number',
    width: 180,
    ellipsis: true,
    sorter: true,
  },
  {
    title: '产品名称',
    dataIndex: 'name',
    width: 240,
    ellipsis: true,
    sorter: true,
  },
  {
    title: '产品条码',
    dataIndex: 'barcode',
    width: 180,
    ellipsis: true,
  },
  {
    title: '分类',
    dataIndex: 'category_name',
    width: 120,
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'is_active',
    scopedSlots: { customRender: 'is_active' },
    width: 60,
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: "right",
    width: 160,
    ellipsis: true,
  },
]