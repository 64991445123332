import request from "@/utils/request";

// Material
export function materialList(params) {
  return request({ url: `/materials/`, method: "get", params });
}

export function materialCreate(data) {
  return request({ url: `/materials/`, method: "post", data });
}

export function materialUpdate(data) {
  return request({ url: `/materials/${data.id}/`, method: "put", data });
}

export function materialDestroy(data) {
  return request({ url: `/materials/${data.id}/`, method: "delete", data });
}

export function materialAddClient(data) {
  return request({ url: `/materials/${data.id}/add_client/`, method: "post", data });
}

export function materialRemoveClient(data) {
  return request({ url: `/materials/${data.id}/remove_client/`, method: "post", data });
}

// Batch
export function batchList(params) {
  return request({ url: `/batchs/`, method: "get", params });
}

export function batchUpdate(data) {
  return request({ url: `/batchs/${data.id}/`, method: "put", data });
}

// SubBatch
export function subBatchList(params) {
  return request({ url: `/sub_batchs/`, method: "get", params });
}

export function subBatchUpdate(data) {
  return request({ url: `/sub_batchs/${data.id}/`, method: "put", data });
}

export function subBatchTransition(data) {
  return request({ url: `/sub_batchs/${data.id}/transition/`, method: "post", data });
}

export function subBatchExport(params) {
  return request({
    url: `/sub_batchs/export/`,
    method: "get",
    responseType: "blob",
    params,
  });
}

export function emptyInventory(data) {
  return request({ url: `/sub_batchs/empty_inventory/`, method: "post", data });
}

// Inventory
export function inventoryList(params) {
  return request({ url: `/inventories/`, method: "get", params });
}

export function inventoryTotal(params) {
  return request({ url: `/inventories/total_amount/`, method: "get", params });
}

export function inventoryExport(params) {
  return request({ url: `/inventories/export/`, responseType: "blob", method: "get", params });
}


export function materialImageUpload(data) {
  return request({
    url: "/material_images/",
    method: "post",
    data,
  });
}

